import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useRef } from 'react';
import { ChipField, Datagrid, DateField, List, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceManyField, SelectArrayInput, Show, SingleFieldList, Tab, TabbedShowLayout, TextField, TopToolbar, useListContext, useRecordContext } from 'react-admin';
import { PrintButton, ReportPrint, FillinsDatagrids } from '../../components';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from '../../components/ListPagination';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  filterTablePrint: {
    display: 'none',
    '@media print': {
      display: 'block'
    },
    marginBottom: 10,
  },
  titleCard: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));



const FilterTablePrint = ({ attendantName }) => {
  const classes = useStyles();
  const { filterValues } = useListContext();

  return (
    <Datagrid bulkActionButtons={false} data={[{ filters: { ...filterValues, attendantName } }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <ReferenceArrayField label="Combustíveis" source="fuelIds" textAlign="center" basePath="fuels" reference="fuels">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="Postos" source="stationIds" textAlign="center" basePath="stations" reference="stations">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="attendantName" label="Frentista" />
    </Datagrid>
  );
};

const ListFillins = (props) => {
  const attendant = useRecordContext();
  const classes = useStyles();
  const fillinsRef = useRef();

  const ListFillinsActions = () => (
    <TopToolbar>
      <PrintButton
        printRef={fillinsRef}
      />
    </TopToolbar>
  );

  const filterFillin = [
    <BetweenDatesInput alwaysOn />,
    <ReferenceArrayInput
      source="stationIds"
      reference="stations"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectArrayInput
        optionText={"name"}
        style={{ minWidth: 180 }}
        label="Postos"
      />
    </ReferenceArrayInput>,
    <ReferenceArrayInput
      source="fuelIds"
      reference="fuels"
      perPage={null}
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectArrayInput
        optionText={"name"}
        style={{ minWidth: 180 }}
        label="Combustíveis"
      />
    </ReferenceArrayInput>
  ];

  return (
    <List
      title=" "
      basePath="fillins"
      resource="fillins"
      sort={{ field: "createdAt", order: "DESC" }}
      filterDefaultValues={{
        from: moment().startOf("day").subtract(1, "month").toISOString(),
        to: moment().endOf("day").toISOString(),
      }}
      filter={{ attendantId: attendant.id, showSummary: true }}
      disableSyncWithLocation
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      actions={<ListFillinsActions />}
      filters={filterFillin}
    >
      <ReportPrint ref={fillinsRef} title="Abastecimentos" landscape>
        <div className={classes.filterTablePrint}>
          <Typography className={classes.titleCard}>Filtros</Typography>
          <FilterTablePrint attendantName={attendant.name} />
        </div>

        <FillinsDatagrids {...props} bulkActionButtons={false} />
      </ReportPrint>
    </List>
  );
};


const AttendantShow = (props) => (
  <Show title="Frentista" {...props}>
    <TabbedShowLayout>
      <Tab label="Resumo">
        <TextField label="ID" source="id" />
        <TextField label="Nome" source="name" />
        <TextField label="Identifid" source="identfid" />
        <TextField label="Código" source="code" />
        <ReferenceManyField
          label="Posto"
          reference="station-attendants"
          target="id"
          sortable={false}
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField label="Posto" source="placeId" reference="stations">
              <TextField source="name" />
            </ReferenceField>
            <DateField label="Último Abastecimento" source="lastFillin" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Abastecimentos" path="fillins">
        <ListFillins />
      </Tab>
    </TabbedShowLayout>

  </Show>
);

export default AttendantShow;
